$font-family-base: "Hind", sans-serif;
$headings-font-family: "Palanquin", sans-serif;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";

//

@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/print";

@include media-breakpoint-up(md) {
  .w-md-75 {
    width: 70% !important;
  }
  .h-md-100 {
    height: 100% !important;
  }
  .border-tr {
    border-top-right-radius: 0;
  }
  .border-tl {
    border-top-left-radius: 0;
  }
  .border-br {
    border-bottom-right-radius: 0;
  }
  .border-bl {
    border-bottom-left-radius: 0;
  }
}

.top-right-bottom-left-0 {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
